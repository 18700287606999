<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/Ersa-VERSAFLOW-ONE-900x675.png"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
              <br><br><br>
              <div class="row">
                <div class="col-2"></div>
                <div class="mt-1 col-6">
                  <h1 class="font-size-48 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                     <h2>VERSAFLOW ONE: THE ONE. FOR EVERYONE.</h2>
                  <br />
                  <h5>
                    Your entry into Selectice Soldering Excellence: Top quality
                    with a compact footprint - the VERSAFLOW ONE is the new
                    entry into the portfolio of Ersa VERSAFLOW inline selective
                    soldering systems. The system combines proven Ersa DNA and
                    customer-oriented option structure with high availability at
                    extremely attractive acquisition as well as operating costs.
                  </h5>
                </div>
                </div>
              </div>
             
            </div>
            <!-- end row -->

            <br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xl-7">
                        <!-- 16:9 aspect ratio -->
                        <div class="ratio ratio-21x9">
                          <iframe
                            style="width: 600px; height: 300px"
                            title="YouToube Video"
                            src="https://www.youtube.com/embed/_9pp9VkY4yw"
                            allowfullscreen=""
                          ></iframe>
                        </div>
                      </div>

                      <div class="col-xl-5">
                        <br /><br />
                        <h1>VERSAFLOW ONE</h1>
                        <h3>
                          Your entry into selective soldering: top quality in
                          the smallest space - the VERSAFLOW ONE is the new
                          entry into the world of Ersa VERSAFLOW inline
                          selective soldering systems. The system combines
                          proven Ersa DNA and customer-oriented options with
                          high availability at extremely attractive acquisition
                          and operating costs.
                        </h3>
                      </div>
                      <!-- end col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <br /><br />
            <div>
              <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="2000"
                controls
                indicators
                background="#fff"
                style="text-shadow: 1px 1px 2px #333"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <div class="carousel-item" active>
                  <div class="parent d-flex justify-content-center">
                    <img
                      class="d-block img-fluid"
                      src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/11.jpg"
                      alt="category.name"
                    />
                  </div>
                  <br />
                </div>
                <div class="carousel-item">
                  <div class="parent d-flex justify-content-center">
                    <img
                      class="d-block img-fluid"
                      src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/12.jpg"
                      alt="category.name"
                    />
                  </div>
                  <br />
                </div>
              </b-carousel>
              <br /><br />
              <!-- video -->
            </div>

            <br /><br />
            <div class="container">
              <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="2000"
                controls
                indicators
                background="#fff"
                style="text-shadow: 1px 1px 2px #333"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <div class="carousel-item" active>
                  <div class="parent d-flex justify-content-center">
                    <img
                      class="d-block img-fluid"
                      src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/13csm_Ersa-VERSAFLOW-ONE-01-1920x540_23d143e0d1.jpg"
                      alt="category.name"
                    />
                  </div>
                  <br />
                  <div class="parent d-flex justify-content-center">
                    <p class="font-size-10">
                      VERSAFLOW ONE - highest quality and highest throughput
                      with compact line dimensions
                    </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="parent d-flex justify-content-center">
                    <img
                      class="d-block img-fluid"
                      src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/14csm_Ersa-VERSAFLOW-ONE-03-1920x540_d664b52851.jpg"
                      alt="category.name"
                    />
                  </div>
                  <br />
                  <div class="parent d-flex justify-content-center">
                    <p class="font-size-10">
                      VERSAFLOW ONE: fast and easy soldering program creation
                      including stable processes for perfect soldering results
                    </p>
                  </div>
                </div>

                <div class="carousel-item">
                  <div class="parent d-flex justify-content-center">
                    <img
                      class="d-block img-fluid"
                      src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/15csm_Ersa-VERSAFLOW-ONE-02-1920x540_6aecf553cb.jpg"
                      alt="category.name"
                    />
                  </div>
                  <br />
                  <div class="parent d-flex justify-content-center">
                    <p class="font-size-10">
                      VERSAFLOW ONE: proven and future-proof technology from the
                      world-leading VERSAFLOW world
                    </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="parent d-flex justify-content-center">
                    <img
                      class="d-block img-fluid"
                      src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/16csm_Ersa_VERSAFLOW-ONE-01-1920x540px_fdbff5b863.jpg"
                      alt="category.name"
                    />
                  </div>
                  <br />
                  <div class="parent d-flex justify-content-center">
                    <p class="font-size-10">
                      VERSAFLOW ONE: short delivery times thanks to series
                      production with the most popular configurationfeatures
                      available directly from stock
                    </p>
                  </div>
                </div>
              </b-carousel>
              <br /><br />
             
            </div>
<br><br><br>
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/17csm_Ersa-VERSAFLOW-ONE-Features-01-900x600_48154883b2.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <br><br>
                <div class="mt-4">
                  <h1>VERSAFLOW ONE - Basic Equipment</h1>
                  <br />
                  <ul class="container">
                    <li>
                      <h3>
                       3 mm pin chain transport with automatic transport width adjustment
                      </h3>
                    </li>
                    <br />
                    <li>
                      <h3>127 μm spray head with 1.8 l flux tank</h3>
                    </li>
                    <br />
                    <li>
                      <h3>IR emitter at the bottom</h3>
                    </li>
                    <br />
                    <li>
                      <h3>
                       1 solder pot with electromagnetic pump
                      </h3>
                    </li>
                    <br />
                    <li>
                      <h3>ERSASOFT 5</h3>
                    </li>
                    <br />
                    
                   
                   
                  </ul>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>